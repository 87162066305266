import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { shuffle } from 'lodash';
import { firestore } from '../firebaseConfig'; // Importa a instância do Firestore
import { collection, getDocs } from 'firebase/firestore'; // Importa funções do Firestore
import '../App.css';
import Newsletter from '../components/Newsletter';
import Southamerica from '../components/Southamerica';
import ScrollToTop from '../components/ScrollToTop';

const App = () => {
  const { dominioNome } = useParams();
  const [dominios, setDominios] = useState([]);
  const [firestoreData, setFirestoreData] = useState([]);
  const [letraFiltro, setLetraFiltro] = useState('');
  const [termoBusca, setTermoBusca] = useState('');
  const [filtroSetor, setFiltroSetor] = useState('');
  const [setores, setSetores] = useState([]);
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [selectedCard, setSelectedCard] = useState(null);
  const [mensagem, setMensagem] = useState('');
  const [selectedValue, setSelectedValue] = useState('');

  const dominiosPorPagina = 11;

  const fetchData = async () => {
    try {
      const querySnapshot = await getDocs(collection(firestore, 'dominios'));
      const data = [];
      const setores = new Set();
      querySnapshot.forEach((doc) => {
        const dominio = doc.data();
        data.push(dominio);
        setores.add(dominio.setor);
      });
      setFirestoreData(data);
      setDominios(shuffle(data));
      setSetores(Array.from(setores).sort());
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handlePriceClick = (id) => {
    const updatedDominios = dominios.map((dominio) => {
      if (dominio.id === id) {
        return { ...dominio, exibirPreco: !dominio.exibirPreco };
      }
      return dominio;
    });
    setDominios(updatedDominios);
  };

  const handleFilter = (letra) => {
    setLetraFiltro(letra);
    setFiltroSetor('');
  };

  const handleBusca = (event) => {
    const { value } = event.target;
    setTermoBusca(value);
  };

  const handleFiltroSetor = (event) => {
    const { value } = event.target;
    setFiltroSetor(value);
    setLetraFiltro('');
  };

  const handlePaginaAnterior = () => {
    if (paginaAtual > 1) {
      setPaginaAtual(paginaAtual - 1);
      window.scrollTo(0, 0);
    }
  };

  const handleProximaPagina = () => {
    const totalPages = Math.ceil(filteredDominios.length / dominiosPorPagina);
    if (paginaAtual < totalPages) {
      setPaginaAtual(paginaAtual + 1);
      window.scrollTo(0, 0);
    }
  };

  const preencherMensagem = (dominio) => {
    setMensagem(`Olá, tenho interesse no domínio: ${dominio.nome}. Valor: ${selectedValue}`);
  };

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleSubmit = () => {
    alert(mensagem);  // Aqui você pode substituir por qualquer ação, como enviar a mensagem por email
  };

  const filteredDominiosBySetor = filtroSetor
    ? dominios.filter((dominio) => dominio.setor === filtroSetor)
    : dominios;

  const filteredDominiosByTermoBusca = termoBusca
    ? filteredDominiosBySetor.filter((dominio) =>
        dominio.nome.toLowerCase().includes(termoBusca.toLowerCase())
      )
    : filteredDominiosBySetor;

  const filteredDominios = letraFiltro
    ? filteredDominiosByTermoBusca.filter((dominio) =>
        dominio.nome.toLowerCase().startsWith(letraFiltro.toLowerCase())
      )
    : filteredDominiosByTermoBusca;

  const indiceInicial = (paginaAtual - 1) * dominiosPorPagina;
  const dominiosPaginados = filteredDominios.slice(
    indiceInicial,
    indiceInicial + dominiosPorPagina
  );

  const totalPages = Math.ceil(filteredDominios.length / dominiosPorPagina);

  const gerarPaginas = () => {
    const paginas = [];
    const paginaInicio = Math.max(2, paginaAtual - 1);
    const paginaFim = Math.min(paginaAtual + 1, totalPages - 1);

    if (totalPages > 3) {
      paginas.push(1);
      if (paginaInicio > 2) paginas.push('...');
      for (let i = paginaInicio; i <= paginaFim; i++) {
        paginas.push(i);
      }
      if (paginaFim < totalPages - 1) paginas.push('...');
      if (totalPages > 1) paginas.push(totalPages);
    } else {
      for (let i = 1; i <= totalPages; i++) {
        paginas.push(i);
      }
    }

    return paginas;
  };

  const paginas = gerarPaginas();

  return (
    <div className="App">
      <div className={`card-container ${dominiosPaginados.length === 1 ? 'single-result' : ''}`}>
        <p className="card-title">Vitrine Eletrônica de Domínios</p>
        <div className="banner">
          <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
            <h4>Transforme sua ideia em realidade com domínios exclusivos</h4>
          </Link>
        </div>
        <div className="card-busca">
          <input
            type="text"
            placeholder="Pesquisar domínio"
            value={termoBusca}
            onChange={handleBusca}
          />
  
          <div className="selectbox">
            <label htmlFor="filtroSetor" className="visually-hidden">Filtre por setor</label>
            <select
              id="filtroSetor"
              value={filtroSetor}
              onChange={handleFiltroSetor}
            >
              <option value="">Todos os setores</option>
              {setores.map((setor) => (
                <option key={setor} value={setor}>
                  {setor}
                </option>
              ))}
            </select>
          </div>
        </div>
  
        {/* Verificação se não houver domínios */}
        {dominiosPaginados.length === 0 ? (
          <p className="mensagem-nenhum-dominio">Nenhum domínio encontrado.</p>
        ) : (
          dominiosPaginados.map((dominio) => (
            <div className="card" key={dominio.id}>
              <h3>
                <Link to={`/${dominio.nome}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                  {dominio.nome.toLowerCase()}
                </Link>
              </h3>
              <a href={`http://www.${dominio.nome}`} target="_self" rel="noopener noreferrer"></a>
              <p className="setor">{dominio.setor}</p>
              {dominio.status === 'Consultar' ? (
                <Link to={`/Contato/${dominio.nome}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                  <div
                    className={`button button-${dominio.status.toLowerCase()}`}
                    onClick={() => preencherMensagem(dominio)}
                  >
                    <p>{dominio.status}</p>
                  </div>
                </Link>
              ) : (
                <div className={`button button-${dominio.status.toLowerCase()}`}>
                  <p>{dominio.status}</p>
                </div>
              )}
            </div>
          ))
        )}
      </div>
  
      <div className="pagination">
        <button onClick={handlePaginaAnterior} disabled={paginaAtual === 1}>
          Voltar
        </button>
        {paginas.map((pagina, index) => (
          <button
            key={index}
            className={`pagination-button ${pagina === paginaAtual ? 'active' : ''}`}
            onClick={() => {
              if (pagina !== '...') setPaginaAtual(pagina);
            }}
          >
            {pagina}
          </button>
        ))}
        <button
          onClick={handleProximaPagina}
          disabled={paginaAtual === totalPages}
        >
          Avançar
        </button>
      </div>
  
      <ScrollToTop />
      <Newsletter />
      <Southamerica />
    </div>
  );
  
};

export default App;
